/** @jsx jsx */
import { jsx } from "theme-ui"

const Button = props => (
  <div
    sx={{
      fontFamily: "body",
      display: "inline-block",
      backgroundColor: "white",
      color: "grey",
      fontWeight: 'heading',
      fontSize: 1,
      py: 1,
      px: 3,
      transition: "0.3s",
      borderRadius: '5px',
      ":hover": {
        color: "white",
        opacity: 90,
        backgroundColor: 'primary',
        cursor: "pointer",
        boxShadow: '2px 2px #f6f6f6'
      },
    }}
    {...props}
  />
)

export default Button
